import React from "react"
import Image from "../image"
import { BannerStyledSecond } from "../../styles/pages/home"
import ReactPlayer from "react-player"

const MainSecond = () => {
  return (
    <BannerStyledSecond>
      <article id="ContainerVideo">
        <div className="container-figure">
          <Image name="picturePink" className="imagePink" />
          <Image name="pictureGreen" className="imageGreen" />
        </div>
        <div className="container">
          <ReactPlayer
            width="100%"
            height="100%"
            className="react-player"
            url="https://youtu.be/rprOhcqsD5s"
          />
        </div>
      </article>
      <article id="ContainerText">
        <h1>¡Transformamos tu búsqueda de empleo desde el primer instante!</h1>
        <p>
          Encontrar empleo no es algo que debas hacer solo. Hemos diseñado
          nuestro programa de empleabilidad integrando nuestra tecnología y
          asesoría experta. ¡Te compartiremos nuestros hacks exclusivos para
          elevar tu perfil!
        </p>
      </article>
    </BannerStyledSecond>
  )
}

export default MainSecond
