import React from "react"
import Carousel, {
  slidesToShowPlugin,
  arrowsPlugin,
} from "@brainhubeu/react-carousel"
import { TeamAccompanimentStyled } from "../../styles/pages/home"
import { ArrowIosForward, ArrowIosBack } from "styled-icons/evaicons-solid"
import logo from "../../images/imgAboutFour.png"
import "@brainhubeu/react-carousel/lib/style.css"

const TeamAccompaniment = () => {
  const [dotValue, setDotValue] = React.useState(0)

  const isBrowser = () => typeof window !== "undefined"

  const onChangeDot = newValue => {
    setDotValue(newValue)
  }

  const onPrevItem = () => {
    setDotValue(prevState => prevState - 1)
  }

  const onNextItem = () => {
    setDotValue(prevState => prevState + 1)
  }

  const arrowsPluginObject = {
    resolve: arrowsPlugin,
    options: {
      arrowLeft: (
        <button onClick={onPrevItem} className="button-nav">
          <ArrowIosBack className="icon" />
        </button>
      ),
      arrowRight: (
        <button onClick={onNextItem} className="button-nav">
          <ArrowIosForward className="icon" />
        </button>
      ),
      addArrowClickHandler: false,
    },
  }

  const couches = [
    {
      name: "Tulia Valdez",
      position: "Career Coach",
      img: "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/academy%2Fteam%2Fv2%2FTULIA.png?alt=media&token=588a01c6-b630-40a9-a613-2130197ea55c",
      linkedin: "https://www.linkedin.com/in/tuliavaldez/",
    },
    {
      name: "Alejandra Isosorbe",
      position: "Career Coach & Community",
      img: "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/academy%2Fteam%2Fv2%2FALE.png?alt=media&token=2e5acaa0-c766-459e-a298-ed08cd45c8ed",
      linkedin: "https://www.linkedin.com/in/alejandra-isosorbe/",
    },
    {
      name: "Paz Zarza",
      position: "Academy Support Lead",
      img: "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/academy%2Fteam%2Fv2%2FPAZ.png?alt=media&token=db51c21c-57e2-40cd-acd7-6f6e52a5d18c",
      linkedin: "https://www.linkedin.com/in/paz-zarza/",
    },
    {
      name: "Mariangela Osorio",
      position: "Employment & Collection Analyst",
      img: "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/academy%2Fteam%2Fv2%2FMARIANGELA.png?alt=media&token=3b47881b-6706-428f-971b-b7d3e4b61b22",
      linkedin: "https://www.linkedin.com/in/mariangela-marin/",
    },
    {
      name: "Erick Ramírez",
      position: "Manager",
      img: "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/academy%2Fteam%2Fv2%2FERICK.png?alt=media&token=ef99b426-055b-4c97-bdff-79127e48f34d",
      linkedin: "https://www.linkedin.com/in/erickrcolunga",
    },
  ]

  React.useEffect(() => {
    const interval = setInterval(() => {
      setDotValue(prevValue =>
        prevValue === couches.length - 1 ? 0 : prevValue + 1
      )
    }, 5000)

    return () => clearInterval(interval)
  }, [])

  return (
    <TeamAccompanimentStyled>
      <section id="ContainerOne">
        <h2> Equipo de acompañamiento </h2>
        <h1> ¡Conoce al team! </h1>
        <article id="carousel">
          <div id="carouselMain">
            {isBrowser() && (
              <Carousel
                onChange={onChangeDot}
                value={dotValue}
                plugins={[
                  "infinite",
                  {
                    resolve: slidesToShowPlugin,
                    options: {
                      numberOfSlides: 3,
                    },
                  },
                  { ...arrowsPluginObject },
                ]}
                breakpoints={{
                  1024: {
                    plugins: [
                      {
                        resolve: slidesToShowPlugin,
                        options: {
                          numberOfSlides: 2,
                        },
                      },
                      { ...arrowsPluginObject },
                    ],
                  },
                  768: {
                    plugins: [
                      {
                        resolve: slidesToShowPlugin,
                        options: {
                          numberOfSlides: 1,
                        },
                      },
                      { ...arrowsPluginObject },
                    ],
                  },
                }}
              >
                {couches.map((item, idx) => {
                  return (
                    <section className="itemCarousel" key={idx}>
                      <img src={item.img || logo} alt="logo" />
                      <p className="name">{item.name}</p>
                      <p className="position">{item.position}</p>
                      {item.linkedin && (
                        <a
                          href={item.linkedin}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Visita su LinkedIn
                        </a>
                      )}
                    </section>
                  )
                })}
              </Carousel>
            )}
          </div>
        </article>
      </section>
      <section id="percentage">
        <article>
          <h1> +200 </h1>
          <p>vidas cambiadas</p>
        </article>
        <article>
          <h1>2</h1>
          <p>meses en promedio de colocación</p>
        </article>
        <article>
          <h1>$0</h1>
          <p>pago inicial</p>
        </article>
        <article>
          <h1>+400</h1>
          <p>empresas confían en LaPieza</p>
        </article>
      </section>
    </TeamAccompanimentStyled>
  )
}

export default TeamAccompaniment
