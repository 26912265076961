import React from "react"
import { FaqStyled } from "../../styles/pages/home"
import {
  ArrowIosDownwardOutline,
  ArrowIosUpwardOutline,
} from "styled-icons/evaicons-outline"

const dataLoad = [
  {
    question: "¿Qué es un piezero?",
    answer:
      "Llamamos piezeros a todos los miembros de nuestro programa que se unen a nuestra comunidad para encontrar empleo.",
  },
  {
    question: "¿Qué es un Career Coach?",
    answer:
      "Tu Career Coach es un profesional en temas de reclutamiento, empleabilidad y Recursos Humanos que te acompañará a lo largo del programa y te orientará hasta conseguir empleo.",
  },
  {
    question: "¿Cómo sé si este programa es para mí?",
    answer:
      "Si estás en México y buscas empleo de tiempo completo este programa es para ti. No importa si actualmente cuentas o no con un trabajo, nuestro programa está diseñado para ayudarte a encontrar empleo lo más rápido posible.",
  },
  {
    question: "¿Cuánto tiempo debo dedicarle al programa?",
    answer:
      "Los tiempos pueden variar, sin embargo, es un programa intensivo, por lo que tendrás que tener tiempo disponible para el programa y para los procesos de selección. Al menos dedicarás una hora diaria. ",
    // btn: "Conoce el plan a tu medida",
    // extraMax: "¿Crees que es demasiado? No pasa nada."
  },
  {
    question: "¿Cuánto tiempo dura el programa?",
    answer:
      "Nuestro programa termina cuando consigues empleo. En promedio, nuestros estudiantes consiguen su meta en 1 mes y medio, sin embargo, el programa puede tener una duración máxima de 4 meses.",
  },
  {
    question: "¿Solo puedo aplicar a vacantes de LaPieza?",
    answer:
      "¡No! El bot aplicará a vacantes en distintos portales de empleo de manera automática. También te enseñaremos a postular efectivamente por tu cuenta.",
  },
  {
    question: "¿Cuánto cuesta el programa?",
    answer: `1 Mes de sueldo bruto de tu nuevo empleo diferido a 3 meses. Recuerda que únicamente pagarás si consigues empleo.`,
  },
  {
    question: "¿Qué pasa si no consigo empleo en 4 meses?",
    answer:
      "La mayoría de nuestros piezeros consiguen empleo, pero si tú no inicias un proceso de reclutamiento exitoso en 4 meses, entonces no pagarás absolutamente nada.",
  },
  {
    question: "¿Para qué países está abierto el programa?",
    answer:
      // "Nuestro Bootcamp está abierto para toda la comunidad de habla hispana en el mundo exceptuando algunos países donde aún no iniciamos operaciones.",
      "Actualmente nuestro programa solo está disponible para personas en México, pero esperamos muy pronto llegar a otros países.",
  },
  // {
  //   question: "¿Qué pasa si consigo empleo por mi cuenta?",
  //   answer:
  //     // "Nuestro Bootcamp está abierto para toda la comunidad de habla hispana en el mundo exceptuando algunos países donde aún no iniciamos operaciones.",
  //     "Si consigues empleo por tu cuenta tu Career Coach te seguirá apoyando y orientando durante todo el proceso, y solo deberás pagar el 50% de tu primer mes de sueldo bruto.",
  // },
]

const Faq = () => {
  const [selectQuestion, setSelectQuestion] = React.useState("")

  const onSelectChange = value => {
    if (value === selectQuestion) {
      setSelectQuestion("")
    } else {
      setSelectQuestion(value)
    }
  }

  return (
    <FaqStyled id="faqs">
      <h1 className="title"> Preguntas Frecuentes </h1>
      <section id="questionFaq">
        {dataLoad?.map((item, idx) => (
          <div id={idx === selectQuestion ? "active" : ""} key={idx}>
            <article onClick={() => onSelectChange(idx)}>
              <h1>{item?.question}</h1>
              {idx === selectQuestion ? (
                <ArrowIosUpwardOutline className="icon" />
              ) : (
                <ArrowIosDownwardOutline className="icon" />
              )}
            </article>
            {idx === selectQuestion ? (
              <p dangerouslySetInnerHTML={{ __html: item?.answer }} />
            ) : null}
          </div>
        ))}
      </section>
      <section id="footer">
        <h1> ¿Tienes alguna otra pregunta? </h1>
        <h2> Estamos para ayudarte, escríbenos en nuestro chat virtual :) </h2>
      </section>
    </FaqStyled>
  )
}

export default Faq
