import React from "react"
import { BannerStyledProfileWorks } from "../../styles/pages/home"
import Image from "../image"

const ProfileWorks = () => {
  return (
    <BannerStyledProfileWorks>
      <h1> Perfiles que hemos ayudado a conseguir empleo </h1>
      <article id="ctxOne">
        <div className="containerIcon">
          <Image name="dw2" className="imgLogo" />
          <h3> Desarrollo Web</h3>
        </div>
        <div className="containerIcon">
          <Image name="dataScience2" className="imgLogo" />
          <h3> Ciencia e Ingeniería de Datos </h3>
        </div>
        <div className="containerIcon">
          <Image name="marketing2" className="imgLogo" />
          <h3> Mercadotecnia</h3>
        </div>
        <div className="containerIcon">
          <Image name="sales2" className="imgLogo" />
          <h3> Ventas </h3>
        </div>
      </article>
      <article id="ctxTwo">
        <div className="containerIcon">
          <Image name="accounting2" className="imgLogo" />
          <h3> Finanzas y Contabilidad</h3>
        </div>
        <div className="containerIcon">
          <Image name="dataEngineering2" className="imgLogo" />
          <h3> Administración </h3>
        </div>
        <div className="containerIcon">
          <Image name="HR2" className="imgLogo" />
          <h3> Recursos Humanos</h3>
        </div>
        <div className="containerIcon">
          <Image name="uiUx2" className="imgLogo" />
          <h3> Diseño UX/UI </h3>
        </div>
        <div className="containerIcon">
          <Image name="productManagement2" className="imgLogo" />
          <h3> Producto </h3>
        </div>
      </article>
    </BannerStyledProfileWorks>
  )
}

export default ProfileWorks
