import React from "react"
import { NewPromiseStyled } from "../../styles/pages/home"
import Image from "../image"
import imageUnion from "../../images/imageUnion.jpeg"

const dataLoad = [
  {
    key: 1,
    text: "Aplica llenando el formulario",
    description:
      "Evaluaremos tu solicitud y nos pondremos en contacto contigo.",
  },
  {
    key: 2,
    text: "¡Felicidades, ya entraste!",
    description:
      "Conoce a tu Career Coach el miércoles y explícale qué estás buscando.",
  },
  {
    key: 3,
    text: "Mejoremos tu perfil",
    description:
      "Haremos tu CV, tu LinkedIn y tu perfil en distintos portales de empleo más atractivos",
  },
  // {
  //   key: 4,
  //   text: "Que la IA trabaje por ti",
  //   description:
  //     "Tenemos un bot que buscará vacantes para ti y aplicará de manera automática.",
  // },
  {
    key: 5,
    text: "Brilla en las entrevistas",
    description:
      "Tu Career Coach te brindará entrenamiento especializado para entrevistas, asegurando que te sientas confiado y preparado para cada oportunidad.",
  },
  {
    key: 6,
    text: "Construye en comunidad",
    description:
      "Participa en sesiones semanales con nuestra comunidad, donde abordamos temas clave de empleabilidad,  compartimos experiencias y consejos.",
  },
  {
    key: 7,
    text: "Avanza en tus entrevistas",
    description:
      "Comienza procesos de entrevistas con distintas empresas y aclara todas tus dudas vía WhatsApp con tu Career Coach.",
  },
  {
    key: 8,
    text: "Consigue empleo",
    description:
      "¡Así es! El momento que estabas esperando ha llegado. Contarás con nuestra asesoría para negociar como un experto.",
  },
  {
    key: 9,
    text: "Paga el Programa",
    description:
      "El pago corresponde a un mes de sueldo bruto de tu nuevo empleo diferido a tres meses.",
  },
]

const NewPromise = () => {
  return (
    <NewPromiseStyled>
      <section id="ContainerVideo">
        <div className="container">
          <div className="imagePick">
            <Image name="picturePinkTwo" />
          </div>
          <Image name="pictureYellow" className="imageYellow" />
          <div id="imageGeneral">
            <img src={imageUnion} alt="" />
          </div>
        </div>
      </section>
      <section id="ContainerDescription">
        <article id="header">
          <h2>Nuestra Promesa</h2>
          <h1>¡Solo pagas si consigues empleo gracias a nosotros!</h1>
          <h3 />
          {/*<h3> Si no te convence la primera sesión, puedes dar de baja el programa </h3>*/}
          <button
            type="button"
            id="applicantTwo"
            onClick={() => window.open("https://form.typeform.com/to/UyF3aITf")}
          >
            Aplica ahora
          </button>
        </article>
        <section id="containerList">
          {dataLoad?.map(item => (
            <article key={item?.key}>
              <span> {item?.key} </span>
              <div className="containerDescription">
                <p> {item?.text} </p>
                <h4> {item?.description} </h4>
              </div>
            </article>
          ))}
        </section>
      </section>
    </NewPromiseStyled>
  )
}

export default NewPromise
