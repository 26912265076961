import * as React from "react"
import { BackgroundGraphic } from "../../styles/pages/home"
import Carousel, { slidesToShowPlugin, Dots } from "@brainhubeu/react-carousel"
import aboutUsPotential from "../../images/aboutUsPotential.png"
import meli from "../../images/meli.png"
import femsa from "../../images/femsa.png"

import "@brainhubeu/react-carousel/lib/style.css"

const GraphicPotential = () => {
  const isBrowser = () => typeof window !== "undefined"

  const [dotValue, setDotValue] = React.useState(0)

  const onChangeDot = newValue => {
    setDotValue(newValue)
  }

  return (
    <BackgroundGraphic>
      <section id="imageMainContainer">
        <article id="description">
          <h2>Nuestro alcance</h2>
          <h1> Te ayudaremos a conectar con miles de empresas! </h1>
          <h3>
            Estas son algunas empresas donde nuestros egresados han sido
            contratados gracias a nuestro programa
          </h3>
        </article>
        <img src={aboutUsPotential} alt="" id="imageMain" />
      </section>
      {isBrowser() && (
        <>
          <Carousel
            onChange={onChangeDot}
            className="custom-carousel"
            value={dotValue}
            plugins={[
              "infinite",
              {
                resolve: slidesToShowPlugin,
                options: {
                  numberOfSlides: 1,
                },
              },
            ]}
          >
            <figure className="company-figure">
              <img src={meli} alt="" />
            </figure>
            <figure className="company-figure">
              <img src={femsa} alt="" />
            </figure>
          </Carousel>
          <Dots
            className=""
            value={dotValue}
            onChange={onChangeDot}
            number={2}
            thumbnails={[1, 2, 3].map((i, idx) => {
              const key = `carousel-item-key${idx}`
              return <div key={key} className="dot-custom" />
            })}
          />
        </>
      )}
    </BackgroundGraphic>
  )
}
export default GraphicPotential
