import * as React from "react"
import Seo from "../components/seo"
import { HomeWrapper } from "../styles/pages/home"
import GraphicPotential from "../components/home/graphicPotential"
import AboutAcademy from "../components/home/aboutAcademy"
import TeamAccompaniment from "../components/home/teamAccompaniment"
import TestimonialsSection from "../components/home/testimonialsSection"
import NewPromise from "../components/home/newPromise"
import MainFirst from "../components/home/mainFirst"
import MainSecond from "../components/home/mainSecond"
import ProfileWorks from "../components/home/profileWorks"
import ExitSection from "../components/home/exitSection"
import Faq from "../components/home/faq"
import Layout from "../components/layout"

const IndexPage = () => (
  <Layout>
    <Seo title="Programa de Empleabilidad" />
    <HomeWrapper>
      <MainFirst />
      <MainSecond />
      <ProfileWorks />
      <GraphicPotential />
      <AboutAcademy />
      <TeamAccompaniment />
      <TestimonialsSection />
      <NewPromise />
      <ExitSection />
      <Faq />
    </HomeWrapper>
  </Layout>
)

export default IndexPage
