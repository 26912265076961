import React from "react"
import { BackgroundAbout } from "../../styles/pages/home"
import Image from "../image"
import { ArrowIosForward } from "styled-icons/evaicons-solid"
import { Link } from "gatsby"

const dataLoad = [
  // {
  //   img: "imgAboutOne",
  //   title: "Postulaciones con IA",
  //   description:
  //     "Conectamos tu perfil a inteligencia artificial, que postulará automáticamente por ti a las vacantes que mejor se alineen con tus habilidades y experiencias.",
  // },
  {
    img: "imgAboutTwo",
    title: "Optimización de CV y LinkedIn",
    description:
      "Deja que nuestros expertos transformen tu CV y perfil de LinkedIn, garantizando que destaques ante los reclutadores e incrementes tus oportunidades de conseguir empleo.",
  },
  {
    img: "imgAboutThree",
    title: "Contenido de capacitación",
    description:
      "Nuestros especialistas han diseñado para ti un programa de e-learning donde aprenderás los mejores hacks de reclutamiento y empleabilidad.",
  },
  {
    img: "imgAboutFour",
    title: "Seguimiento individual",
    description:
      "Sabemos que el camino puede ser difícil, por lo cual tendrás asignado un Career Coach que te acompañará y te brindará asesorías personalizadas a lo largo del programa.",
  },
  {
    img: "imgAboutFour",
    title: "Únete a la comunidad",
    description:
      "Podrás conectar con profesionales de distintos países, realizar networking y construir una red de apoyo para expandir tu alcance.",
  },
]

const AboutAcademy = () => {
  return (
    <BackgroundAbout>
      <section id="description">
        <h2> ¿Cómo funciona Academy By LaPieza? </h2>
        <h1> Nuestro programa </h1>
      </section>
      <section id="containerList">
        {dataLoad?.map((item, idx) => (
          <article className="boxItem" key={idx.toString()}>
            <div className="imageItem">
              <Image name={item?.img} className="imgGeneral" />
            </div>
            <h1>{item?.title}</h1>
            <p> {item?.description} </p>
          </article>
        ))}
      </section>
      <h3 id="moreDetail">
        <Link to="/info">Conoce más detalles</Link>{" "}
        <ArrowIosForward size="24" />
      </h3>
    </BackgroundAbout>
  )
}

export default AboutAcademy
