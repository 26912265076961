import React from "react"
import { BtnApplicantStyled } from "../../styles/header"
import Link from "gatsby-link"
import { BackgroundSecond, BannerStyledMain } from "../../styles/pages/home"

const MainFirst = () => {
  return (
    <BannerStyledMain>
      <section id="containerText">
        <h1>¡Descubre nuestro Programa de Empleabilidad para México!</h1>
        <p>
          Hemos creado una ruta que te guiará hacia tu próximo trabajo. Lo mejor
          es que ¡solo pagas si consigues empleo!
        </p>
        <article id="buttonContainer">
          <BtnApplicantStyled>
            <a
              href="https://form.typeform.com/to/UyF3aITf"
              target="_blank"
              rel="noopener noreferrer"
            >
              Aplica hoy
            </a>
          </BtnApplicantStyled>
          <Link to="/info">Conoce más{" >"}</Link>
        </article>
      </section>
      <BackgroundSecond />
    </BannerStyledMain>
  )
}

export default MainFirst
