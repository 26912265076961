import React from "react";
import { TestimonialsStyled } from "../../styles/pages/home"

const dataLoad = [
  {
    key: 1,
    video: "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/academy%2Ftestimonios%2FJulio%20Cesar.jpg?alt=media&token=25d8da4d-b128-49b5-a53f-ba4f2feb6fdc",
    name: "Julio César Cantú Páez",
    position: "Jr Developer-Full Stack en docupass",
    time: "1 mes y 3 días"
  },
  {
    key: 2,
    video: "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/academy%2Ftestimonios%2FLuis%20Manuel.jpg?alt=media&token=cc8a15f7-86a1-4fd6-929f-56519dfeddae",
    name: "Luis Manuel Domiguez Aguilar",
    position: "Guild Business Development en Crehana",
    time: "27 días"
  },
  {
    key: 3,
    video: "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/academy%2Ftestimonios%2FIrving%20Toledo%20ok.jpg?alt=media&token=4b6689ba-df02-4ab9-afdc-2438dd2b867d",
    name: "Irving Toledo Vázquez",
    position: "Coordinador de ventas Latinoamérica en Impulsa",
    time: "19 días"
  }
];

const TestimonialsSection = () => {
  return (
    <TestimonialsStyled>
      <h2 id="titleTwo" > Testimonios  </h2>
      <h1 id="title"> Conoce nuestros casos de éxito</h1>
      <section id="containerTestimonial">
        {
          dataLoad?.map(
            (data,idx) => (
              <article className="itemTestimonial" key={idx.toString()}>
                <div id="imageContainer">
                  <img src={data?.video} className="video" />
                </div>
                <h1> {data?.name} </h1>
                <h2> {data?.position} </h2>
                <h3> {data?.time} </h3>
              </article>
            )
          )
        }


      </section>
    </TestimonialsStyled>
  )
}
export default TestimonialsSection;